const { buildNamespacesMap } = require('@wix/wix-code-viewer-utils');
const { createClient } = require('@wix/sdk/client');
const { site } = require('@wix/site');

const WIX_CODE_APP_DEF_ID = '675bbcef-18d8-41f5-800e-131ec9e08762';

const createGlobals = ({
  active$wBiFactory,
  $w,
  wixSdk,
  userConsole,
  getAppDefIdFromPackageName,
}) => {
  const wrapped$w = active$wBiFactory.wrapFunctionReturnValueWithBi($w);
  wrapped$w.at = active$wBiFactory.wrapFunctionCallWithBi($w.at, $w);

  const wrappedWixSdk = buildNamespacesMap(
    wixSdk,
    self.fetch.bind(self),
    active$wBiFactory.wrapObjectPropertiesWithBi,
  );
  const clientSdk = {
    async invoke({ applicationId, namespace, method, args }) {
      if (wixSdk?.[namespace]?.[method]) {
        if (typeof wixSdk[namespace][method] === 'function') {
          return wixSdk[namespace][method](...args);
        } else {
          return wixSdk[namespace][method];
        }
      } else {
        console.error('Wix Site SDK - Method not found', {
          applicationId,
          namespace,
          method,
        });
      }
    },
  };
  const client = createClient({
    host: site.host({ applicationId: WIX_CODE_APP_DEF_ID, clientSdk }),
    auth: {
      getAuthHeaders() {
        return {
          headers: {
            Authorization:
              wixSdk.elementorySupport.getRequestOptions().headers
                .Authorization,
          },
        };
      },
    },
  });

  return {
    $w: wrapped$w,
    $ns: wrappedWixSdk,
    console: userConsole,
    $wixContext: {
      version: 3,
      client,
      clientSdk,
    },
    elementorySupport: wixSdk.elementorySupport,
    getAppDefIdFromPackageName, // corresponding change in the bundling side can be found here: https://github.com/wix-private/cloud-runtime/pull/4025
  };
};

module.exports = {
  createGlobals,
};
