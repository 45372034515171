const ERROR_NAME = 'ResolveMissingPlatformNamespacesError';

class ResolveMissingPlatformNamespacesError extends Error {
  constructor(namespace, reason) {
    super(
      `Failed to resolve platform namespaces "${namespace}" with error: ${reason}`,
    );
    this.name = ERROR_NAME;
    this.namespace = namespace;
    this.reason = reason;
  }
}

module.exports.ResolveMissingPlatformNamespacesError = ResolveMissingPlatformNamespacesError;
module.exports.ERROR_NAME = ERROR_NAME;
